import { Typography, useTheme, Link } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";

const AdvertWidget = () => {

    const { palette } = useTheme();
    const dark = palette.neutral.dark;
    const main = palette.neutral.main;
    const medium = palette.neutral.medium;

    return (
        <WidgetWrapper>
            <FlexBetween>
                <Typography color={dark} variant="h5" fontWeight="500">
                    Sponsored
                </Typography>
                <Typography color={medium}>Create Ad</Typography>
            </FlexBetween>
            <img
                width="100%"
                height="auto"
                alt="advert"
                src="/assets/threejsad.jpg"
                style={{borderRadius: "0.75rem", margin: "0.75rem 0"}}
            />
            <FlexBetween>
                <Typography color={main}>Mathew Kennedy</Typography>
                <Link href="https://mathewkennedy.com/threejsblog" color={medium}>www.mathewkennedy.com</Link>
            </FlexBetween>
            <Typography color={medium} m="0.5rem 0">
                Full Stack Web developer and developer of this website. Looking to work and learn as part of a creative, passionate team.
                Take a look at my Three JS blog!
            </Typography>
        </WidgetWrapper>
    )

}   

export default AdvertWidget;
import { Box } from "@mui/material";
import { styled } from "@mui/system";

// Box is passed to styled function, and this returns a function
// we then call that passing in our options object
// now FlexBetween has css properties that can can be set as props on component
const FlexBetween = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"  
})

export default FlexBetween;
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Form from "./Form";

const LoginPage = () => {

    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

    return (
        <Box>
            <Box width="100%" backgroundColor={theme.palette.background.alt} p="1rem 6%" text-align="center">
                <Typography
                    fontWeight="bold"
                    // clamp sets min, preferred, and max
                    fontSize="32px"
                    color="primary"
                >
                DEVoid
                </Typography>
            </Box>
            <Box
                width={isNonMobileScreens ? "50%" : "90%" }
                p="2rem"
                m="2rem auto"
                borderRadius="1.5rem"
                backgroundColor={theme.palette.background.alt}
            >
                <Typography fontWeight="500" variant="h5" sx={{ mb: "1.5rem "}}>
                    Welcome to DEVoid, the Social Media for indie game developers.
                </Typography>
                <Form />
            </Box>
        </Box>
    )
}
export default LoginPage;
import {
  ManageAccountsOutlined,
  EditOutlined,
  LocationOnOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { Box, Typography, Divider, useTheme } from "@mui/material";
import UserImage from "components/UserImage";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InProgressModal from "./InProgressModal";

const UserWidget = ({ userId, picturePath }) => {
  const [user, setUser] = useState(null); // user in component state
  const loggedInUser = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;

  // bool to say whether user on their own homepage
  const isLoggedInUser = userId === loggedInUser; // true if logged in user viewing own page

  // backend not returning user, catch firing in getUser on user router
  const getUser = async () => {
    const response = await fetch(`/users/${userId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setUser(data);
  };

  useEffect(() => {
    getUser();
  }, []);

  if (!user) {
    // prevent error in destructuring below by returning null if no user
    return null;
  }

  const {
    firstName,
    lastName,
    location,
    occupation,
    viewedProfile,
    impressions,
    friends,
  } = user;

  return (
    <WidgetWrapper>
      {/* FIRST ROW */}
      <FlexBetween
        gap="0.5rem"
        pb="1.1rem"
        onClick={() => navigate(`/profile/${userId}`)}
      >
        <FlexBetween gap="1rem">
          <UserImage image={picturePath} />
          <Box>
            <Typography
              variant="h4"
              color={dark}
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: palette.primary.light,
                  cursor: "pointer",
                },
              }}
            >
              {firstName} {lastName}
            </Typography>
            <Typography color={medium}>
              {friends ? friends.length : 0} friends
            </Typography>
          </Box>
        </FlexBetween>

        {/* change ManageAccountsOutlined to add friend button - will need to copy patchFriend function in */}
        {/* {!isLoggedInUser && <ManageAccountsOutlined />} */}
      </FlexBetween>

      <Divider />

      {/* SECOND ROW */}
      <Box p="1rem 0">
        <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
          <LocationOnOutlined fontSize="large" sx={{ color: main }} />
          <Typography color={medium}>{location}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="1rem">
          <WorkOutlineOutlined fontSize="large" sx={{ color: main }} />
          <Typography color={medium}>{occupation}</Typography>
        </Box>
      </Box>

      <Divider />

      {/* THIRD ROW */}
      <Box padding="1rem 0">
        <FlexBetween mb="0.5rem">
          <Typography color={medium}>Profile Views</Typography>
          <Typography color={main} fontWeight="500">
            {viewedProfile}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography color={medium}>Post Views</Typography>
          <Typography color={main} fontWeight="500">
            {impressions}
          </Typography>
        </FlexBetween>
      </Box>

      <Divider />

      {/* FOURTH ROW */}
      <Box p="1rem 0">
        <Typography fontSize="1rem" color={main} fontWeight="500" mb="1rem">
          Social Profiles
        </Typography>

        {/* first social icon */}
        <FlexBetween gap="1rem" mb="0.5rem">
          <FlexBetween gap="1rem">
            <img src="/assets/twitter.png" alt="twitter" />
            <Box>
              <Typography color={main} fontWeight="500">
                Twitter
              </Typography>
              <Typography
                color={medium}
              >{`twitter.workinprogress.com/${firstName}`}</Typography>
            </Box>
          </FlexBetween>
          {/* <EditOutlined sx={{ color: main }} /> */}
          <InProgressModal type="social" />
        </FlexBetween>

        {/* second social icon */}
        <FlexBetween gap="1rem">
          <FlexBetween gap="1rem">
            <img src="/assets/linkedin.png" alt="LinkedIn" />
            <Box>
              <Typography color={main} fontWeight="500">
                LinkedIn
              </Typography>
              <Typography
                color={medium}
              >{`linkedin.workinprogress.com/${firstName}`}</Typography>
            </Box>
          </FlexBetween>
          {/* <EditOutlined sx={{ color: main }} /> */}
          <InProgressModal type="social" />
        </FlexBetween>
      </Box>
    </WidgetWrapper>
  );
};

export default UserWidget;

import * as React from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import FlexBetween from "components/FlexBetween";
import {
  ShareOutlined,
  Twitter,
  Instagram,
  Facebook,
  GifBoxOutlined,
  AttachFileOutlined,
  ArrowOutwardOutlined,
} from "@mui/icons-material";

import {
  Box,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const InProgressModal = ({ type }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // get palette
  const { palette } = useTheme();
  const mediumMain = palette.neutral.mediumMain;
  const main = palette.neutral.main;

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  // modal titles dependent on which feature is in progress ("type" prop)
  const modalTitles = {
    share: "Where would you like to share this?",
    clip: "Add clip",
    attachment: "Add attachment",
    social: "Social account link",
  };

  // modal descriptions dependent on which feature is in progress ("type" prop)
  const modalDescriptions = {
    share: "Share feature in progress!",
    clip: "Add clip feature in progress!",
    attachment: "Add attachment feature in progress!",
    social: "Feature in progress!",
  };
  return (
    <div>
      {/* buttons dependeont on which feature in progress ("type" prop) */}
      {type == "share" && (
        <IconButton onClick={handleOpen}>
          <ShareOutlined />
        </IconButton>
      )}
      {type == "clip" && (
        <FlexBetween gap="0.25rem" onClick={handleOpen}>
          <GifBoxOutlined sx={{ color: mediumMain, cursor: "pointer" }} />
          <Typography color={mediumMain} sx={{ cursor: "pointer" }}>
            Clip
          </Typography>
        </FlexBetween>
      )}
      {type == "attachment" && (
        <FlexBetween gap="0.25rem" onClick={handleOpen}>
          <AttachFileOutlined sx={{ color: mediumMain, cursor: "pointer" }} />
          <Typography color={mediumMain} sx={{ cursor: "pointer" }}>
            {isNonMobileScreens && "Attachment"}
          </Typography>
        </FlexBetween>
      )}
      {type == "social" && (
        <ArrowOutwardOutlined
          sx={{ color: main, cursor: "pointer" }}
          onClick={handleOpen}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ padding: "0" }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalTitles[type]}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "hotpink" }}
          >
            {modalDescriptions[type]}
          </Typography>
          {/* container for share icons showed if type is share */}
          {type == "share" ? (
            <>
              {" "}
              <Twitter
                sx={{
                  color: "grey",
                  mt: 3,
                  mr: 2,
                  cursor: "pointer",
                  "&:hover": { color: "hotpink" },
                }}
              />
              <Instagram
                sx={{
                  color: "grey",
                  mt: 3,
                  mr: 2,
                  cursor: "pointer",
                  "&:hover": { color: "hotpink" },
                }}
              />
              <Facebook
                sx={{
                  color: "grey",
                  mt: 3,
                  mr: 2,
                  cursor: "pointer",
                  "&:hover": { color: "hotpink" },
                }}
              />
            </>
          ) : (
            ""
          )}
          <DialogActions sx={{ padding: 0 }}>
            <Button sx={{ padding: 0 }} onClick={handleClose}>
              close
            </Button>
          </DialogActions>
        </Box>
      </Modal>
    </div>
  );
};

export default InProgressModal;
